/* Please Write New Classes unless you know what you are doing */

/* Media query for different screen sizes */
@media only screen and (min-width: 960px) and (max-width: 1079px) {
}

@media only screen and (min-width: 768px) and (max-width: 959px) {
}

@media only screen and (max-width: 767px) {
}

@media only screen and (min-width: 480px) and (max-width: 767px) {
}

body {
  margin: 0;
  padding: 0;
  color: #3e4348;
}

/* Home Screen background image */
.homeAppBody {
  position: relative;
  background-image: url('./assets/img/homescreen/mira.JPG');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

/* Border Radius */
.br-5 {
  border-radius: 5px;
}

/* Overflow */
.overflow-hidden {
  overflow: hidden;
}

/* Border */
.border-blue {
  border: 1px solid #3db2d8 !important;
}

/* Margin Top */
.mt-8 {
  margin-top: 8px;
}

.mt-7 {
  margin-top: 7px;
}

.mt--5 {
  margin-top: -5px;
}

/* Margin Left */
.ml-21 {
  margin-left: 21px;
}

.ml-16 {
  margin-left: 16px;
}

.mleft-4 {
  margin-left: 4px !important;
}

/* Align Items */
.ai-fe {
  align-items: flex-end;
}

/* Margin Bottom */
.mb-20 {
  margin-bottom: 20px;
}

.mb-31 {
  margin-bottom: 31px;
}

/* Margin Right */
.mr-31 {
  margin-right: 20px;
}

.mr-17 {
  margin-right: 17px;
}

/* Font Size */
.fs-small {
  font-size: small;
}

.fs-17 {
  font-size: 17px;
}

.fs-14 {
  font-size: 14px;
}

/* Flex Direction */
.fd-row {
  flex-direction: row;
}

/* Display */
.display-inline {
  display: inline;
}

/* Theming Card Header */
.card-header {
  background-color: #fff;
}

/* Theming card Footer */
.card-footer {
  background-color: #fff;
  border-top: none;
}

/* Cursor pointer class */
.cp {
  cursor: pointer;
}

/* Color of a link */
.colorLink {
  color: #20a8d8;
}

/* Color White */
.colorWhite {
  color: #fff;
}

/* Header Styling */
.header-fixed .app-header {
  background-color: #262a2e;
  border-bottom-color: #262a2e;
}

/* Top 50 % */
.topFifty {
  position: absolute;
  top: 45%;
  left: 15%;
  margin-left: -(X/2) px;
  margin-top: -(Y/2) px;
}

/* Increase powered by top */
.topinc {
  position: relative;
  top: 45%;
  left: 15%;
  margin-left: -(X/2) px;
  margin-top: -(Y/2) px;
}

/* Styling for react-table plugin rerender */
.ReactTable .rt-thead .rt-resizable-header-content {
  text-align: left;
}

.-sort-asc {
  box-shadow: none !important;
}

.-sort-asc:before {
  content: '\25B2';
  position: absolute;
  right: 5px;
  top: 8px;
}

.-sort-desc {
  box-shadow: none !important;
}

.-sort-desc:before {
  content: '\25BC';
  position: absolute;
  right: 5px;
  top: 8px;
}

.ReactTable .rt-thead .rt-resizable-header-content {
  line-height: 23px;
}

.ReactTable .rt-thead .rt-tr {
  height: 35px;
}

.ReactTable .-pagination .-btn {
  color: #20a8d8;
  background-color: transparent;
  border: 1px solid #20a8d8;
  width: auto;
  min-width: 100px;
  margin: auto;
}

.ReactTable .-pagination .-btn:not([disabled]):hover {
  background-color: #20a8d8;
  color: #fff;
}

.noBorder {
  border: none;
}

.capacity {
  text-align: center;
}
/* Make show correct time in react date picker */
.react-datepicker__time-container
  .react-datepicker__time
  .react-datepicker__time-box
  ul.react-datepicker__time-list {
  padding-left: 10px;
}
.react-datepicker__input-container input {
  padding: 0.375rem 0.75rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border: 1px solid #e4e7ea;
  border-radius: 0.25rem;
  width: 100%;
}

.react-datepicker__input-container {
  width: 100%;
}

.react-datepicker-wrapper {
  width: 100%;
}

/* Title Text styling */
.titleText {
  font-weight: 500;
  color: #3e4348;
}

/* Header Icon Styling */
.navbar-nav .nav-link {
  color: #fff;
}

.navbar-nav:hover {
  color: #fff;
}

.nav-link:hover {
  color: #fff;
}

.padding3 {
  padding: 3px;
}

.margin3 {
  margin: 3px;
}

.border-bottom0 {
  border-bottom-width: 0px !important;
}

.parent-container {
  display: inline-flex;
}
.width-md-4 {
  width: 33% !important;
}
@media only screen and (max-width: 768px) {
  .width-xs-12 {
    width: 100% !important;
  }
}

.relativePosition-Zindex {
  position: relative;
  z-index: 2;
}

.nav-tabs .nav-link.active {
  background: #fff;
  color: #20a8d8 !important;
  font-weight: 500;
}
.nav-link {
  padding: 0.4rem 1.3rem;
}

a:not([href]):not([tabindex]) {
  color: darkgray;
  font-weight: 400;
  text-decoration: none;
}
